import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import axios from "axios"

const Main = ({ pageContext: { lang } }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("it")
  const [fileList, setFileList] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchFileList = async () => {
      setFileList(null)
      try {
        const result = await axios.get(`fileList/${selectedLanguage.toLowerCase()}.json`)
        setFileList(result.data)
        setLoading(false)
        setError(null)
      } catch (e) {
        setLoading(false)
        setError(e)
      }
    }

    fetchFileList()
  }, [selectedLanguage])


  return (
    <Layout lang={lang}>
      <SEO title={lang.title} description={lang.description} lang={lang.key}/>
      <section className="fw-video" id={"video-section"}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="small-12 medium-12 large-7 cell fw-video-wrapper">
              <iframe title="vimeo-player" src="https://player.vimeo.com/video/403789809" width="640" height="360"
                      frameBorder="0" allowFullScreen className="fw-iframe-embed"></iframe>
            </div>
            <div className="small-12 medium-12 large-5 cell fw-video-desc grid-x">
              <h2 className="fw-title small-12 cell">{lang.videoSection.title}</h2>
              <div className="small-12 cell fw-desc">
                {lang.videoSection.p1}
                <br/><br/>
                {lang.videoSection.p2}
                <br/><br/>
                {lang.videoSection.p3}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id={"download-section"} className="fw-download"
               style={{ background: "url(/img/jpg/bg-02.jpg) center center no-repeat", backgroundSize: "cover" }}>
        <section className="fw-download-filters">
          <div className="grid-container fw-filters">
            <div className="grid-x grid-margin-x">
              <h2 className="fw-title-con small-12 cell text-center">{lang.downloadSection.title}</h2>
              <form className="small-12 cell grid-x grid-margin-x small-up-1 medium-up-2">
                <div className="fw-select-wrap cell grid-x">
                  <label className="small-12 medium-4 cell">{lang.downloadSection.country}</label>
                  <select className="small-12 medium-8 cell" name="country">
                    {lang.downloadSection.countryList.map(country => (
                      <option key={country} value={country}>{country}</option>
                    ))}
                  </select>
                </div>
                <div className="fw-select-wrap cell grid-x">
                  <label className="small-12 medium-4 cell">{lang.downloadSection.language}</label>
                  <select className="small-12 medium-8 cell" name="lang" onChange={e => {
                    setSelectedLanguage(e.target.value)
                  }}>
                    {lang.downloadSection.languageList.map(item => (
                      <option selected={lang.key === item.key} key={item.key} value={item.key}>{item.lang}</option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
          </div>
        </section>
        <section className="fw-download-results">
          <div className="grid-container">
            {loading ? null : (
              <>
                {fileList ? (
                  <ul className="grid-x grid-margin-x small-up-1 medium-up-3">
                    {fileList.map(file => (
                      <li key={file.label} className="cell grid-x">
                        <article className="small-12 cell grid-x">
                          <header className="small-12 cell grid-x">
                            <h4 className="small-12 cell">{file.label}</h4>
                          </header>
                          <figure className="small-12 cell grid-x">
                            <a className="small-12 cell grid-x" href={file.url} target="_blank">
                              <img className="small-12 cell" src={file.image}/>
                            </a>
                            <img className="small-12 cell fw-noa" src="/img/svg/elements2.svg"/>
                          </figure>
                          <a href={file.url} target="_blank" className="fw-link-dl small-12 cell">Download</a>
                        </article>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </>
            )}
          </div>
        </section>
      </section>
      <script src="/js/vendor/jquery.js"></script>
      <script src="/js/vendor/what-input.js"></script>
      <script src="/js/vendor/foundation.js"></script>
      <script src="/js/app.js"></script>
    </Layout>
  )
}

export default Main
